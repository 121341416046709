









import LabSim from '@/tasks/components/simulations/LabSim.vue';
import {defineComponent, PropType} from '@vue/composition-api';

interface IDiproticTitrationInput {
  MWAcid: number;
  Ka1: number;
  Ka2: number;
}

export default defineComponent({
  name: 'LabDiproticTitration',
  components: {LabSim},
  inheritAttrs: false,
  props: {
    Ka1: {
      type: Number as PropType<number>,
      default: 1.0e-3,
    },
    Ka2: {
      type: Number as PropType<number>,
      default: 4.6e-6,
    },
    MWAcid: {
      type: Number as PropType<number>,
      default: 150.087,
    },
  },
  computed: {
    labData(): IDiproticTitrationInput {
      return {
        MWAcid: this.MWAcid,
        Ka1: this.Ka1,
        Ka2: this.Ka2,
      };
    },
    stringifiedLabData(): string {
      return JSON.stringify(this.labData);
    },
  },
});
