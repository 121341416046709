<template>
  <lab-task><lab-diprotic-titration :ka1="Ka1" :ka2="Ka2" :m-w-acid="MWAcid" /></lab-task>
</template>

<script>
import LabDiproticTitration from '../simulations/LabDiproticTitration';
import LabTask from './labs/LabTask';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'DiproticTitrationSIM',
  components: {LabDiproticTitration, LabTask},
  mixins: [DynamicQuestionMixin()],
  computed: {
    version() {
      return this.taskState.getValueBySymbol('version').content.value;
    },
    versionData() {
      switch (this.version) {
        case 1:
          // Selenous acid
          return {
            MWAcid: 128.97,
            Ka1: 2.4e-3,
            Ka2: 4.8e-8,
          };
        case 2:
          // Malonic acid
          return {
            MWAcid: 104.061,
            Ka1: 1.5e-3,
            Ka2: 2.0e-7,
          };
        case 3:
          // Maleic acid
          return {
            MWAcid: 116.072,
            Ka1: 1.4e-2,
            Ka2: 8.6e-7,
          };
        case 4:
          // Malic acid
          return {
            MWAcid: 134.0874,
            Ka1: 3.9e-4,
            Ka2: 7.8e-8,
          };
        default:
          // Tartaric acid
          return {
            MWAcid: 150.087,
            Ka1: 1.0e-3,
            Ka2: 4.6e-7,
          };
      }
    },
    MWAcid() {
      return this.versionData.MWAcid;
    },
    Ka1() {
      return this.versionData.Ka1;
    },
    Ka2() {
      return this.versionData.Ka2;
    },
  },
});
</script>
